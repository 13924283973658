// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    .iwroot, .masthead {
        display: none;
    }

    .resume-root {
        color: black;
        font-size: 11px;
    }

    .contact-header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .print-only {
        display: unset;
    }
    .print-info {
        display: none;
    }

    .resume-root h1 {
        margin-right: var(--spacing400);
        font-size: 16px;
    }

    .resume-root h2 {
        font-size: 16px;
        text-decoration: underline;
    }

    .resume-root .info-header {
        font-size: 13px;
    }

    .info-header h3 {
        flex: 1;
    }

    .resume-root ul {
        margin-top: 4px;
    }

    .qr-code {
        position: absolute;
        right: 0;
        top: 5px;
        width: 75px;
        height: 75px;
    }

}`, "",{"version":3,"sources":["webpack://./src/routes/resume/print.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,mBAAmB;QACnB,mBAAmB;IACvB;IACA;QACI,cAAc;IAClB;IACA;QACI,aAAa;IACjB;;IAEA;QACI,+BAA+B;QAC/B,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,0BAA0B;IAC9B;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,OAAO;IACX;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,QAAQ;QACR,QAAQ;QACR,WAAW;QACX,YAAY;IAChB;;AAEJ","sourcesContent":["@media print {\n    .iwroot, .masthead {\n        display: none;\n    }\n\n    .resume-root {\n        color: black;\n        font-size: 11px;\n    }\n\n    .contact-header {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n    }\n    .print-only {\n        display: unset;\n    }\n    .print-info {\n        display: none;\n    }\n\n    .resume-root h1 {\n        margin-right: var(--spacing400);\n        font-size: 16px;\n    }\n\n    .resume-root h2 {\n        font-size: 16px;\n        text-decoration: underline;\n    }\n\n    .resume-root .info-header {\n        font-size: 13px;\n    }\n\n    .info-header h3 {\n        flex: 1;\n    }\n\n    .resume-root ul {\n        margin-top: 4px;\n    }\n\n    .qr-code {\n        position: absolute;\n        right: 0;\n        top: 5px;\n        width: 75px;\n        height: 75px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
