import React from 'react'
import {Link} from "react-router-dom";
import {ExternalLink} from "../components/externalLink";

export const Home = () =>
  <div className='index'>
    <h1>Who is Dan Soskey?</h1>
      

    <p className='about-me'>
        I'm a full-stack software developer by trade who's always expanding my engineering &amp; design skills.
        With these skills I want to build tools that empower human creativity and connection.
    </p>
    <p className='about-me'>
        Outside of software, I'm a hobbyist game designer, maker, &amp; music enthusiast. <Link to='/projects'>My personal projects</Link> focus
        primarily on these pursuits as they make learning new concepts more fun.
        In my ideal future I want to incorporate some of these elements in my work.
        I'm particularly interested in building musical instruments.
    </p>
    <p className='about-me'>
        I'm currently looking for work, either salaried or freelance.
        If you would like to work with me, please send me an email to <code>asks+contact</code> @ this domain.
    </p>
      <h2>external profiles</h2>
      <div className='row'>
          <ExternalLink href="https://www.linkedin.com/in/daniel-soskey-865402b3">linked-in</ExternalLink>
          <ExternalLink href="https://github.com/dsoskey/">github</ExternalLink>
      </div>
  </div>
