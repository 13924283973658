// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.print-only {
    display: none;
}

.print-info {
    font-style: italic;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.print-info button {
    color: var(--secondary-text-color);
}

.resume-root h2 {
    margin-top: var(--spacing300);
    margin-bottom: unset;
}
.resume-root h3 {
    margin-top: unset;
    margin-bottom: unset;
}

.contact-header {
    display: none;
}

.info-block .info-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/routes/resume/web.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,kCAAkC;AACtC;;AAEA;IACI,6BAA6B;IAC7B,oBAAoB;AACxB;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,8BAA8B;AAClC","sourcesContent":[".print-only {\n    display: none;\n}\n\n.print-info {\n    font-style: italic;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.print-info button {\n    color: var(--secondary-text-color);\n}\n\n.resume-root h2 {\n    margin-top: var(--spacing300);\n    margin-bottom: unset;\n}\n.resume-root h3 {\n    margin-top: unset;\n    margin-bottom: unset;\n}\n\n.contact-header {\n    display: none;\n}\n\n.info-block .info-header {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
