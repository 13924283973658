
export enum AsyncLoadedStatus {
    NOT_STARTED,
    LOADING,
    ERROR,
    SUCCESS
}

export interface AsyncLoadedArray<T> {
    status: AsyncLoadedStatus
    data: T[]
}

export type ObjectMap<T> = { [key: string]: T }
