import React from "react";
import {Link} from "react-router-dom";
import {ExternalLink} from "../components/externalLink";
import "./projects.css";
import {CoglibIcon} from "../components/icon/coglibIcon";

interface ProjectCardProps {
    title: string
    href: string
    imageHref: string | React.ReactNode
    imageAlt: string
    description: React.ReactNode
}

const ProjectCard = ({ title, href, imageHref, imageAlt, description }: ProjectCardProps) => {
    const isExternal = href.startsWith('http')
    return <div className='project-card'>
        {isExternal
            ? <ExternalLink className='project-title' href={href}>{title}</ExternalLink>
            : <Link className='project-title' to={href}>{title}</Link>}
        {typeof imageHref === "string"
            ? <img src={imageHref} alt={imageAlt} />
            : imageHref}
        <p className='project-description'>{description}</p>
    </div>
}

const projectData: ProjectCardProps[] = [
    {
        title: 'Cogwork Librarian',
        description: <>
            Cogwork Librarian is an offline-capable web app for brainstorming Magic: the Gathering lists.
            Built on <ExternalLink href='https://scryfall.com'>Scryfall's</ExternalLink> search syntax,
            Cogwork Librarian aims to reinforce this syntax as the standard for searching for Magic cards anywhere.
        </>,
        href: "https://coglib.sosk.watch",
        imageHref: <a href="https://coglib.sosk.watch"><CoglibIcon /></a>,
        imageAlt: 'Cogwork Librarian icon',
    },
    {
        title: 'Boop',
        description: <>
            Additive synthesizer and sampler for Android.
            The UI is currently being reworked to use Jetpack Compose and a{" "}
            <ExternalLink href='https://en.wikipedia.org/wiki/Wicki%E2%80%93Hayden_note_layout'>
                Wicki-Hayden layout
            </ExternalLink>
            .</>,
        href: '/projects/boop',
        imageHref: 'https://s3.us-east-2.amazonaws.com/danielsoskey.com/boop-screenshot-square.png',
        imageAlt: 'screenshot of Boop',
    },
    {
        title: 'Soskwatch Fabs',
        description: <>Personal library of CAD designs, primarily built using OpenSCAD. Designs include sunglasses, dice, and other game pieces.</>,
        href: 'https://github.com/dsoskey/soskwatch-fabs',
        imageHref: 'https://s3.us-east-2.amazonaws.com/danielsoskey.com/hedron.gif',
        imageAlt: 'spinning Jibblet',
    }
]
export const Projects = () => {


    return <div className='project-card-holder'>
        {projectData.map(projectDatum => <ProjectCard key={projectDatum.title} {...projectDatum} />)}
    </div>
}