import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getFileNames } from './config';
import { Boop } from './routes/boop';
import { Home } from './routes/home';
import {Masthead} from "./components/masthead";
import {Projects} from "./routes/projects";
import {TrustedLinks} from "./routes/trustedLinks";
import {Resume} from "./routes/resume/resume";
import './styles.css';
// @ts-ignore
import TailwindCV from './routes/resume/tailwind.mdx'
import {InnertubeWidget} from "./components/icon/innertubeWidget";

render(
  <div className='root'>
    <Router>
      <Masthead />
      <Switch>
        <Route exact path='/'>
          <Home/>
        </Route>
        <Route path='/projects/boop' exact>
          <Boop getFileNames={getFileNames} />
        </Route>
        <Route path='/projects' exact>
          <Projects />
        </Route>
        <Route path="/cv/2e0c3759-a668-4760-8e8e-594b1a62a682" exact>
          <div className="layout-prose"><TailwindCV/></div>
        </Route>
        <Route path='/resume' exact>
          <div className="layout-prose"><Resume /></div>
        </Route>
        <Route path='/links' exact>
          <TrustedLinks />
        </Route>
        <Route path="/the-art-of-rolling">
          <div>
            <h1>How to Roll a Joint</h1>
            <iframe src="https://drive.google.com/file/d/1BTmK1fQom8D2quDBcHDycgFNNCCXpQ2T/preview" width="640" height="480"/>
            <div><em>Shot April 20th, 2020</em></div>
          </div>
        </Route>
        <Route>
          <h1 className='not-found'>Whoa there buddy!</h1>
        </Route>
      </Switch>
    </Router>
    <InnertubeWidget />
  </div>,
  document.getElementById('root'),
)
