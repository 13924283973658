import React from "react";
import {SkeyIcon} from "./skeyIcon";
import {Link, useLocation} from "react-router-dom";
import "./masthead.css";

export const Masthead = () => {
    const { pathname } = useLocation()
    return <div className='masthead'>
        <div><SkeyIcon /></div>
        <div className='links'>
            <Link className={pathname === "/" ? "active" : ""} to='/'>Who am I?</Link>
            <Link className={pathname.startsWith("/projects") ? "active" : ""} to='/projects'>Projects</Link>
            <Link className={pathname === "/resume" ? "active" : ""} to='/resume'>Resume</Link>
            <Link className={pathname === "/links" ? "active" : ""} to='/links'>Trusted Links</Link>
        </div>
    </div>
}