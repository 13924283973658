import React from "react";
import _content from './content.json'
const content = _content as ResumeSection[]
import "./web.css"
import "./print.css"
import {Qrcode} from "./qrcode";

interface ResumeSection {
    sectionTitle: string
    infoBlocks: InfoBlockProps[]
}

interface InfoBlockProps {
    projectName: string
    personalTitle?: string
    timeFrame: string
    details: string[][]
}

const InfoBlock = (props: InfoBlockProps) => {
    return <section className='info-block'>
        <div className='info-header'>
            <h3>
                {props.projectName}
                {props.personalTitle !== undefined && ` | ${props.personalTitle}`}
            </h3>
            <div className='time-frame'>{props.timeFrame}</div>
        </div>
        <ul>
            {props.details.map(([first, ...rest]) => <li key={first}>
                <span>{first}</span>
                {rest.length > 0 && <ul>
                    {rest.map(line => <li key={line}>{line}</li>)}
                </ul>}
            </li>)}
        </ul>
    </section>
}

const Section = (props: ResumeSection) => {
    return <section className='resume-section'>
        <h2>{props.sectionTitle}</h2>
        {props.infoBlocks.map(infoBlock => <InfoBlock key={infoBlock.projectName} {...infoBlock} />)}
    </section>
}

export const Resume = () => {

    return <div className='resume-root'>
        {/* print-only */}
        <div className='contact-header'>
            <h1>Daniel Soskey <span className='contacts'>| asks@danielsoskey.com | 127.0.0.1 | 908.255.8580</span></h1>
            <Qrcode />
        </div>
        <br className='print-only' />
        <h2>Executive summary</h2>
        <p>
            Full-stack software engineer with 6 years of development experience through Amazon and personal side projects.
            Self-motivated and eager to gain a wide breadth of experience, having learned a new programming language for each role.
            Pragmatic problem-solver that understands the business context around engineering solutions and focuses on delivering value.
            Driven to build tools that help improve the lives of fellow humans and enable them to thrive in creativity.
            Key experiences include leading Android development for a design system and developing and maintaining web apps to manage risk across Amazon.
        </p>
        {content.map(section => <Section key={section.sectionTitle} {...section} />)}
        <div className='print-info'>
            Note: this page is designed to be printed. <button onClick={() => window.print()}>print me</button>
        </div>
    </div>
}