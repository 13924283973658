import AWS from 'aws-sdk';

AWS.config.region = 'us-east-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-2:09769bac-97fb-4352-a685-aadba273a8b0',
});

export const S3 = new AWS.S3({ credentials: AWS.config.credentials });

export const DEFAULT_URL = 'https://s3.us-east-2.amazonaws.com';
export const DEFAULT_BUCKET = 'danielsoskey.com';
const DEFAULT_PREFIX = 'boopRelease';

export async function getFileNames(bucketName: string=DEFAULT_BUCKET, prefix: string = DEFAULT_PREFIX): Promise<string[]> {
    const response = await S3.listObjectsV2({ Bucket: bucketName, Prefix: prefix }).promise(); // TODO: Filter out prefix
    return response.Contents
        .filter((content) => content.Key.endsWith('.apk'))
        .sort((a, b) => a.LastModified.getTime() - b.LastModified.getTime())
        .map((content) => content.Key);
        // .map((content) => content.Key.substring(content.Key.indexOf(prefix) + prefix.length));
}
