import React from 'react';
import { DEFAULT_URL, DEFAULT_BUCKET } from '../config';
import { AsyncLoadedArray, AsyncLoadedStatus } from '../types';
import "./boop.css";

interface BoopProps {
    getFileNames: (rootDir?: string) => Promise<string[]>;
}

interface BoopState {
    fileNames: AsyncLoadedArray<string>;
}

export class Boop extends React.Component<BoopProps, BoopState> {
    state: BoopState = { fileNames: { status: AsyncLoadedStatus.NOT_STARTED, data: [] } }
    componentDidMount() {
        this.loadDownloadFiles();
    }

    loadDownloadFiles = async () => {
        this.setState({ fileNames: { status: AsyncLoadedStatus.LOADING, data: [] }});
        try {
            const data = await this.props.getFileNames();
            this.setState({ fileNames: { status: AsyncLoadedStatus.SUCCESS, data } });
        } catch (err) {
            this.setState({ fileNames: { status: AsyncLoadedStatus.ERROR, data: [] }});
        }
    }

    releaseLinks = () => {
        const { fileNames } = this.state;
        switch (fileNames.status) {
            case AsyncLoadedStatus.LOADING:
                return 'Loading releases...';
            case AsyncLoadedStatus.ERROR:
                return 'Failed to load release links';
            case AsyncLoadedStatus.SUCCESS:
                return (
                    <>
                        <h2>releases</h2>

                        {fileNames.data.length > 0 ? (
                            <ul>
                                { fileNames.data.map((fileName, index) => (
                                    <li key={index}><a href={`${DEFAULT_URL}/${DEFAULT_BUCKET}/${fileName}`}>{fileName.substring(fileName.lastIndexOf('/') + 1)}</a></li>
                                ))}
                            </ul>
                        ) : 'No releases found.'}
                    </>
                );
            default:
                return null;
        }
    }

    render() {

        return (
            <div className='boop'>
                <h1>boop</h1>

                <div className='content'>
                    <div className='screenshot'>
                        <img
                            className='screenshot-boop' 
                            src='https://s3.us-east-2.amazonaws.com/danielsoskey.com/boop_screenshot_blue.jpg' width='420px'
                         alt='screenshot of boop'/>
                    </div>
                    <div className='description'>
                        <p>boop is an additive software synthesizer and sampler for Android. boop has been designed with the following tenets:</p>

                        <ol>
                            <li>music is a joyful form of play, so focus on the fun</li>
                            <li>guide new users with sound defaults</li>
                            <li>enable maximum customization for power users</li>
                        </ol>

                        {this.releaseLinks()}
                    </div>
                </div>
                
            </div>
        );
    }
}