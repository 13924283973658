import React from "react";
import {ExternalLink} from "../components/externalLink";

export const TrustedLinks = () => {
    return <div>
        <h1></h1>
        <p>
            In an internet filling up with AI-generated and search engine optimized slop, it's harder than ever to find trustworthy information and human connection.
            This page contains links to resources I trust and sites that promote humans and the beautiful art they create for each other.
        </p>

        <section>
            <h2>computer science, software, and math</h2>
            <p></p>
            <ul>
                <li><ExternalLink href='https://developer.mozilla.org'>mdn</ExternalLink>: the gold standard of vanilla web dev documentation.</li>
                <li>CSS Tricks's guides to <ExternalLink href='https://css-tricks.com/snippets/css/a-guide-to-flexbox/'>flexbox</ExternalLink> and <ExternalLink href='https://css-tricks.com/snippets/css/complete-guide-grid/'>grid</ExternalLink></li>
                <li><ExternalLink href='https://ninjarockstar.dev/css-hex-grids/'>Building a hexagonal grid using CSS grid</ExternalLink></li>
                <li><ExternalLink href='https://www.redblobgames.com/grids/hexagons/'>Red Blob Games's guide to hexagons</ExternalLink></li>
                <li><ExternalLink href='https://missing.csail.mit.edu/'>MIT's Missing CS semester</ExternalLink>: a survey of the most fundamental tools in a Computer Scientist's toolkit.</li>
                <li><ExternalLink href='https://craftinginterpreters.com/'>Crafting Interpreters</ExternalLink>: a free book that teaches you how to build a programming language from scratch.</li>
            </ul>
        </section>
        <section>
            <h2>music</h2>
            <ul>
                <li><ExternalLink href='https://www.koopinstruments.com/home'>KOOP Instruments</ExternalLink></li>
                <li><ExternalLink href='https://pages.mtu.edu/~suits/notefreqs.html'>Note frequency chart</ExternalLink></li>
            </ul>
        </section>
    </div>
}