import React from "react";

const d = `
M 0.598389,52.4761 L 0.995438,52.4336 L 1.38966,52.3701 L 1.77994,52.2857 L 2.16518,52.1807 L 2.54428,52.0552
L 2.91617,51.9098 L 3.2798,51.7448 L 3.63412,51.5607 L 3.97815,51.358 L 4.3109,51.1373 L 4.63142,50.8991
L 4.93883,50.6443 L 5.23222,50.3734 L 5.5108,50.0873 L 5.77374,49.7868 L 6.02032,49.4727 L 6.24985,49.146
L 6.46165,48.8075 L 6.65514,48.4582 L 6.82976,48.0991 L 6.98502,47.7312 L 7.12047,47.3556 L 7.23575,46.9733
L 7.33052,46.5854 L 7.40451,46.193 L 7.4575,45.7972 L 7.48936,45.3992 L 7.5,45 L 7.5,18.1057
L 35.2678,45.8734 L 35.5612,46.1443 L 35.8686,46.3991 L 36.1891,46.6373 L 36.5218,46.858 L 36.8659,47.0607
L 37.2202,47.2448 L 37.5838,47.4098 L 37.9557,47.5552 L 38.3348,47.6807 L 38.72,47.7857 L 39.1103,47.8701
L 39.5045,47.9336 L 39.9016,47.9761 L 40.3003,47.9973 L 40.6996,47.9973 L 41.0984,47.9761 L 41.4954,47.9336
L 41.8897,47.8701 L 42.2799,47.7857 L 42.6652,47.6807 L 43.0443,47.5552 L 43.4162,47.4098 L 43.7798,47.2448
L 44.1341,47.0607 L 44.4781,46.858 L 44.8109,46.6373 L 45.1314,46.3991 L 45.4388,46.1443 L 45.7322,45.8734
L 46.0108,45.5873 L 46.2737,45.2868 L 46.5203,44.9727 L 46.7498,44.646 L 46.9617,44.3075 L 47.1551,43.9582
L 47.3298,43.5991 L 47.485,43.2312 L 47.6205,42.8556 L 47.7357,42.4733 L 47.8305,42.0854 L 47.9045,41.693
L 47.9575,41.2972 L 47.9894,40.8992 L 48,40.5 L 47.9894,40.1008 L 47.9575,39.7028 L 47.9045,39.307
L 47.8305,38.9146 L 47.7357,38.5267 L 47.6205,38.1444 L 47.485,37.7688 L 47.3298,37.4009 L 47.1551,37.0418
L 46.9617,36.6925 L 46.7498,36.354 L 46.5203,36.0272 L 46.2737,35.7132 L 46.0108,35.4127 L 45.7322,35.1266
L 10.6057,-0 L 45.7322,-35.1266 L 46.0108,-35.4127 L 46.2737,-35.7132 L 46.5203,-36.0272 L 46.7498,-36.354
L 46.9617,-36.6925 L 47.1551,-37.0418 L 47.3298,-37.4009 L 47.485,-37.7688 L 47.6205,-38.1444 L 47.7357,-38.5267
L 47.8305,-38.9146 L 47.9045,-39.307 L 47.9575,-39.7028 L 47.9894,-40.1008 L 48,-40.5 L 47.9894,-40.8992
L 47.9575,-41.2972 L 47.9045,-41.693 L 47.8305,-42.0854 L 47.7357,-42.4733 L 47.6205,-42.8556 L 47.485,-43.2312
L 47.3298,-43.5991 L 47.1551,-43.9582 L 46.9617,-44.3075 L 46.7498,-44.646 L 46.5203,-44.9727 L 46.2737,-45.2868
L 46.0108,-45.5873 L 45.7322,-45.8734 L 45.4388,-46.1443 L 45.1314,-46.3991 L 44.8109,-46.6373 L 44.4781,-46.858
L 44.1341,-47.0607 L 43.7798,-47.2448 L 43.4162,-47.4098 L 43.0443,-47.5552 L 42.6652,-47.6807 L 42.2799,-47.7857
L 41.8897,-47.8701 L 41.4954,-47.9336 L 41.0984,-47.9761 L 40.6996,-47.9973 L 40.3003,-47.9973 L 39.9016,-47.9761
L 39.5045,-47.9336 L 39.1103,-47.8701 L 38.72,-47.7857 L 38.3348,-47.6807 L 37.9557,-47.5552 L 37.5838,-47.4098
L 37.2202,-47.2448 L 36.8659,-47.0607 L 36.5218,-46.858 L 36.1891,-46.6373 L 35.8686,-46.3991 L 35.5612,-46.1443
L 35.2678,-45.8734 L 7.5,-18.1057 L 7.5,-45 L 7.48936,-45.3992 L 7.4575,-45.7972 L 7.40451,-46.193
L 7.33052,-46.5854 L 7.23575,-46.9733 L 7.12047,-47.3556 L 6.98502,-47.7312 L 6.82976,-48.0991 L 6.65514,-48.4582
L 6.46165,-48.8075 L 6.24985,-49.146 L 6.02032,-49.4727 L 5.77374,-49.7868 L 5.5108,-50.0873 L 5.23222,-50.3734
L 4.93883,-50.6443 L 4.63142,-50.8991 L 4.3109,-51.1373 L 3.97815,-51.358 L 3.63412,-51.5607 L 3.2798,-51.7448
L 2.91617,-51.9098 L 2.54428,-52.0552 L 2.16518,-52.1807 L 1.77994,-52.2857 L 1.38966,-52.3701 L 0.995438,-52.4336
L 0.598389,-52.4761 L 0.199646,-52.4973 L -0.199646,-52.4973 L -0.598389,-52.4761 L -0.995438,-52.4336 L -1.38966,-52.3701
L -1.77994,-52.2857 L -2.16518,-52.1807 L -2.54428,-52.0552 L -2.91617,-51.9098 L -3.2798,-51.7448 L -3.63412,-51.5607
L -3.97815,-51.358 L -4.3109,-51.1373 L -4.63142,-50.8991 L -4.93883,-50.6443 L -5.23222,-50.3734 L -5.5108,-50.0873
L -5.77374,-49.7868 L -6.02032,-49.4727 L -6.24985,-49.146 L -6.46165,-48.8075 L -6.65514,-48.4582 L -6.82976,-48.0991
L -6.98502,-47.7312 L -7.12047,-47.3556 L -7.23575,-46.9733 L -7.33052,-46.5854 L -7.40451,-46.193 L -7.4575,-45.7972
L -7.48936,-45.3992 L -7.5,-45 L -7.5,-18.1057 L -29.6522,-40.2578 L 4.93883,-71.8814 L 5.23222,-72.1522
L 5.5108,-72.4383 L 5.77374,-72.7388 L 6.02032,-73.0529 L 6.24985,-73.3796 L 6.46165,-73.7182 L 6.65514,-74.0674
L 6.82976,-74.4265 L 6.98502,-74.7944 L 7.12047,-75.1701 L 7.23575,-75.5524 L 7.33052,-75.9403 L 7.40451,-76.3327
L 7.4575,-76.7284 L 7.48936,-77.1265 L 7.5,-77.5256 L 7.48936,-77.9248 L 7.4575,-78.3228 L 7.40451,-78.7186
L 7.33052,-79.111 L 7.23575,-79.4989 L 7.12047,-79.8812 L 6.98502,-80.2569 L 6.82976,-80.6247 L 6.65514,-80.9838
L 6.46165,-81.3331 L 6.24985,-81.6716 L 6.02032,-81.9984 L 5.77374,-82.3125 L 5.5108,-82.613 L 5.23222,-82.8991
L 4.93883,-83.1699 L 4.63142,-83.4248 L 4.3109,-83.6629 L 3.97815,-83.8837 L 3.63412,-84.0864 L 3.2798,-84.2705
L 2.91617,-84.4355 L 2.54428,-84.5809 L 2.16518,-84.7063 L 1.77994,-84.8114 L 1.38966,-84.8958 L 0.995438,-84.9593
L 0.598389,-85.0017 L 0.199646,-85.023 L -0.199646,-85.023 L -0.598389,-85.0017 L -0.995438,-84.9593 L -1.38966,-84.8958
L -1.77994,-84.8114 L -2.16518,-84.7063 L -2.54428,-84.5809 L -2.91617,-84.4355 L -3.2798,-84.2705 L -3.63412,-84.0864
L -3.97815,-83.8837 L -4.3109,-83.6629 L -4.63142,-83.4248 L -4.93883,-83.1699 L -45.4388,-46.1443 L -45.7322,-45.8734
L -46.0108,-45.5873 L -46.2737,-45.2868 L -46.5203,-44.9727 L -46.7498,-44.646 L -46.9617,-44.3075 L -47.1551,-43.9582
L -47.3298,-43.5991 L -47.485,-43.2312 L -47.6205,-42.8556 L -47.7357,-42.4733 L -47.8305,-42.0854 L -47.9045,-41.693
L -47.9575,-41.2972 L -47.9894,-40.8992 L -48,-40.5 L -47.9894,-40.1008 L -47.9575,-39.7028 L -47.9045,-39.307
L -47.8305,-38.9146 L -47.7357,-38.5267 L -47.6205,-38.1444 L -47.485,-37.7688 L -47.3298,-37.4009 L -47.1551,-37.0418
L -46.9617,-36.6925 L -46.7498,-36.354 L -46.5203,-36.0272 L -46.2737,-35.7132 L -46.0108,-35.4127 L -45.7322,-35.1266
L -10.6057,-0 L -45.7322,35.1266 L -46.0108,35.4127 L -46.2737,35.7132 L -46.5203,36.0272 L -46.7498,36.354
L -46.9617,36.6925 L -47.1551,37.0418 L -47.3298,37.4009 L -47.485,37.7688 L -47.6205,38.1444 L -47.7357,38.5267
L -47.8305,38.9146 L -47.9045,39.307 L -47.9575,39.7028 L -47.9894,40.1008 L -48,40.5 L -47.9894,40.8992
L -47.9575,41.2972 L -47.9045,41.693 L -47.8305,42.0854 L -47.7357,42.4733 L -47.6205,42.8556 L -47.485,43.2312
L -47.3298,43.5991 L -47.1551,43.9582 L -46.9617,44.3075 L -46.7498,44.646 L -46.5203,44.9727 L -46.2737,45.2868
L -46.0108,45.5873 L -45.7322,45.8734 L -45.4388,46.1443 L -45.1314,46.3991 L -44.8109,46.6373 L -44.4781,46.858
L -44.1341,47.0607 L -43.7798,47.2448 L -43.4162,47.4098 L -43.0443,47.5552 L -42.6652,47.6807 L -42.2799,47.7857
L -41.8897,47.8701 L -41.4954,47.9336 L -41.0984,47.9761 L -40.6996,47.9973 L -40.3003,47.9973 L -39.9016,47.9761
L -39.5045,47.9336 L -39.1103,47.8701 L -38.72,47.7857 L -38.3348,47.6807 L -37.9557,47.5552 L -37.5838,47.4098
L -37.2202,47.2448 L -36.8659,47.0607 L -36.5218,46.858 L -36.1891,46.6373 L -35.8686,46.3991 L -35.5612,46.1443
L -35.2678,45.8734 L -7.5,18.1057 L -7.5,45 L -7.48936,45.3992 L -7.4575,45.7972 L -7.40451,46.193
L -7.33052,46.5854 L -7.23575,46.9733 L -7.12047,47.3556 L -6.98502,47.7312 L -6.82976,48.0991 L -6.65514,48.4582
L -6.46165,48.8075 L -6.24985,49.146 L -6.02032,49.4727 L -5.77374,49.7868 L -5.5108,50.0873 L -5.23222,50.3734
L -4.93883,50.6443 L -4.63142,50.8991 L -4.3109,51.1373 L -3.97815,51.358 L -3.63412,51.5607 L -3.2798,51.7448
L -2.91617,51.9098 L -2.54428,52.0552 L -2.16518,52.1807 L -1.77994,52.2857 L -1.38966,52.3701 L -0.995438,52.4336
L -0.598389,52.4761 L -0.199646,52.4973 L 0.199646,52.4973 z
`
export const SkeyIcon = () => {

    return <svg width="48px" height="69.5px" viewBox="-48 -86 96 160" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <title>dan soskey's personal logo</title>
        <path transform="translate(6,4)" d={d} fill="var(--secondary-background-color)"/>
        <path d={d} fill="var(--primary-text-color)"/>
</svg>

}