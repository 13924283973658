// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-card-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.project-card-holder > :not(:last-child) {
    margin-right: var(--spacing300);
}

.project-card {
    width: 400px;
    padding: var(--spacing200);
    margin-bottom: var(--spacing300);
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-background-color);
    color: var(--secondary-text-color);
    border: var(--primary-text-color) 2px solid;
    border-radius: var(--default-border-radius);
}

.project-title {
    color: var(--secondary-text-color);
}

.project-card > :not(:first-child) {
    margin-top: var(--spacing200);
}

.project-card a:visited {
    color: unset;
}

.project-card img {
    aspect-ratio: 1/1;
}`, "",{"version":3,"sources":["webpack://./src/routes/projects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,mDAAmD;IACnD,kCAAkC;IAClC,2CAA2C;IAC3C,2CAA2C;AAC/C;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".project-card-holder {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.project-card-holder > :not(:last-child) {\n    margin-right: var(--spacing300);\n}\n\n.project-card {\n    width: 400px;\n    padding: var(--spacing200);\n    margin-bottom: var(--spacing300);\n    display: flex;\n    flex-direction: column;\n    background-color: var(--secondary-background-color);\n    color: var(--secondary-text-color);\n    border: var(--primary-text-color) 2px solid;\n    border-radius: var(--default-border-radius);\n}\n\n.project-title {\n    color: var(--secondary-text-color);\n}\n\n.project-card > :not(:first-child) {\n    margin-top: var(--spacing200);\n}\n\n.project-card a:visited {\n    color: unset;\n}\n\n.project-card img {\n    aspect-ratio: 1/1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
