// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masthead {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing400);
}

.masthead .links {
    margin-left: var(--spacing400);
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.masthead .links > :not(:last-child) {
    margin-right: var(--spacing400);
}

.masthead a {
    padding: var(--spacing300);
    /*text-decoration: none;*/
    font-size: large;
    border-radius: var(--default-border-radius);
}


.masthead a.active {
    color: var(--secondary-text-color);
    background-color: var(--secondary-background-color);
}`, "",{"version":3,"sources":["webpack://./src/components/masthead.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,gBAAgB;IAChB,2CAA2C;AAC/C;;;AAGA;IACI,kCAAkC;IAClC,mDAAmD;AACvD","sourcesContent":[".masthead {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: var(--spacing400);\n}\n\n.masthead .links {\n    margin-left: var(--spacing400);\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n.masthead .links > :not(:last-child) {\n    margin-right: var(--spacing400);\n}\n\n.masthead a {\n    padding: var(--spacing300);\n    /*text-decoration: none;*/\n    font-size: large;\n    border-radius: var(--default-border-radius);\n}\n\n\n.masthead a.active {\n    color: var(--secondary-text-color);\n    background-color: var(--secondary-background-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
